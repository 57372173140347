<template>
	<div>
		<div class="navbar_box">
			<div class="navbar_small_box">
				<div class="navbar_small_left"><img src="../../src/assets/logo.png" alt="" /></div>
				<div class="navbar_small_cen">
					<router-link :class="select==1?'selecti':'selectno'" to="/">
						<div>首页</div>
					</router-link>
					<router-link :class="select==2?'selecti':'selectno'" to="/platform">
						<div>平台介绍</div>
					</router-link>
					<router-link :class="select==3?'selecti':'selectno'" to="/service">
						<div>服务介绍</div>
					</router-link>
					<router-link :class="select==4?'selecti':'selectno'" to="/news">
						<div>新闻中心</div>
					</router-link>
					<router-link :class="select==5?'selecti':'selectno'" to="/enter">
						<div>入驻指南</div>
					</router-link>

				</div>
				<div class="navbar_small_right">
					<div>贴吧</div>
					<img src="../../src/assets/heard.png" alt="" />
					<router-link to="/register">
						<div>皮皮绳<i class="el-icon-arrow-down" style="color: #fff;"></i>
						</div>
					</router-link>
				</div>
			</div>


		</div>

	</div>

</template>
<script>
	export default {
		props: ['select'],
		data() {
			return {}
		},

		components: {

		},

		mounted() {
			console.log(this.select, "select")
		},
		methods: {}
	}
</script>



<style scoped lang="less">
	a {
		text-decoration: none;
	}

	.navbar_box {
		width: 100%;
		height: 80px;
		background-color: #181310;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 9999;
	}

	.navbar_small_box {
		width: 70%;
		height: 80px;
		margin: 0 auto;

		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.navbar_small_left {
		width: 53px;
		height: 53px;

		img {
			width: 53px;
			height: 53px;
		}
	}

	.navbar_small_cen {
		width: 50%;

		display: flex;
		align-items: center;
		justify-content: space-between;


	}

	.selecti {

		color: #fff;
		border-bottom: 3px solid #E8C28D;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		text-align: left;
		font-style: normal;
		padding-top: 30px;
		padding-bottom: 25px;
		color: #E8C28D;

	}

	.selectno {

		color: #fff;
		border-bottom: 3px solid #181310;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		text-align: left;
		font-style: normal;
		padding-top: 30px;
		padding-bottom: 25px;
		color: #CDCDCD;

	}

	.navbar_small_right {
		width: 25%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		img {
			width: 40px;
			height: 40px;
			margin-left: 20px;
			margin-right: 20px;
		}

		div {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			text-align: left;
			font-style: normal;
			color: #CDCDCD;
		}
	}
</style>