<template>
	<div>

		<top :select="select"></top>
		<div style="width: 100%;height: 80px;"></div>
		<div class="index_banner">
			<img src="../assets/bgi2.png" alt="" />
			<div class="bgi_word">
				<div>王者联盟，创联中小企业，面向海量客户市场</div>
				<div>王者联盟，创联中小企业，面向海量客户市场王者联盟，创联中小企业，面向海量客户市场</div>
				<div>联系我们</div>
			</div>
			<div class="bgi_pa">
				<div v-for="(item,index) in list" :key="index" class="bgi_pa_con">
					<img :src="item.img" alt="" />
					<div>
						<div>{{item.name}}</div>
						<div>{{item.name1}}</div>
					</div>
				</div>
			</div>

		</div>
		<div class="all_title" style="margin-top: 180px;">
			<div>线上电商系统</div>
			<div></div>
			<div>六大解决方案大幅度提升零售业营业额和顾客量</div>
		</div>
		<div class="online_box">
			<div class="online_item" v-for="(item,index) in list1" :key="index">
				<div class="online_item_top" :style="index>2?'background: #575757':''">可视化管理</div>
				<div class="online_item_bottom">
					<div>线上数据可视化分析</div>
					<div>数据洞察一目了然</div>
				</div>
			</div>
		</div>
		<div class="all_title">
			<div>新零售电商系统</div>
			<div></div>
			<div>赋能线下门店商业运营关系，与C端消费者建立一致性体验的新链接</div>
		</div>
		<div class="salenew_box">
			<img class="salenew_box_img" src="../../src/assets/salenew.png" alt="" />
			<div class="salenew_box_small">
				<div class="salenew_small_item" v-for="(item,index) in list2" :key="index">
					<img :src="item.img" alt="" />
					<div>{{item.name}}</div>
				</div>
			</div>
		</div>
		<div class="all_title">
			<div>平台服务范围介绍</div>
			<div></div>
			<div>赋能线下门店商业运营关系，与C端消费者建立一致性体验的新链接</div>
		</div>
		<div class="platform_box">

			<div class="platform_item" v-for="(item,index) in list3" :key="index">
				<img :src="item.img" alt="" />
				<div>
					<div>{{item.name}}</div>
					<div>
						{{item.name1}}
					</div>
				</div>

			</div>
		</div>
		<div class="ckgd">查看更多<i class="el-icon-d-arrow-right"></i></div>
		<div class="index_news">
			<div class="index_news_s">
				<div class="xwzx_box">新闻资讯</div>
				<div class="xwzx_box_b">
					<div class="xwzx_box_b_l">
						<div class="swiper-container swiper-container-fluid">
							<div class="swiper-wrapper">
								<div class="swiper-slide"><img src="../pt1.png" alt="" />
									<div class="lbjieshao">文案文案文案文案文案文案文案文案文案文案</div>

								</div>
							</div>
							<div class="swiper-pagination"></div>
						</div>
					</div>
					<div class="xwzx_box_b_r">
						<div class="xwzx_box_b_item" @mouseenter="handleMouseEnter(index)" v-for="(item,index) in list4"
							:key="index">
							<div class="xwzx_box_b_item_l"
								:style="indexs==index?'background: #3665AB;':'background: #000;'"></div>
							<div class="xwzx_box_b_item_r">
								<div>
									<div :style="indexs==index?'color:#B48A38;':'color: #fff;'">蓝信封献爱心 文字间显申请</div>
									<div :style="indexs==index?'color:#B48A38;':'color: #fff;'">2024-12-11</div>
								</div>
								<div :style="indexs==index?'color:#B48A38;':'color: #fff;'">商家服务中心：为进一步提升商家财务管理水平和服务质量…
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<bottom></bottom>







	</div>
</template>

<script>
	import top from '../components/top'
	import Swiper from 'swiper'; // 注意引入的是Swiper
	import 'swiper/css/swiper.min.css' // 注意这里的引入

	import bottom from '../components/bottom'

	export default {
		components: {
			top,
			bottom,

		},
		data() {
			return {
				indexs: "",
				select: 1,
				list4: [{}, {}, {}, {}],
				list3: [{
					img: new URL('../assets/index10.png', import.meta.url).href,
					name: '推拿按摩',
					name1: "今天，您选择了三杉，那您将不再是一个人创业，与厂家同行，成功轻而易举！因为有一个团队为您而战 ，您的事业我们负责到底！"
				}, {
					img: new URL('../assets/index11.png', import.meta.url).href,
					name: '美容美甲',
					name1: "今天，您选择了三杉，那您将不再是一个人创业，与厂家同行，成功轻而易举！因为有一个团队为您而战 ，您的事业我们负责到底！"
				}, {
					img: new URL('../assets/index12.png', import.meta.url).href,
					name: '家政服务',
					name1: "今天，您选择了三杉，那您将不再是一个人创业，与厂家同行，成功轻而易举！因为有一个团队为您而战 ，您的事业我们负责到底！"
				}, {
					img: new URL('../assets/index13.png', import.meta.url).href,
					name: '家电维修',
					name1: "今天，您选择了三杉，那您将不再是一个人创业，与厂家同行，成功轻而易举！因为有一个团队为您而战 ，您的事业我们负责到底！"
				}],
				list2: [{
					img: new URL('../assets/index6.png', import.meta.url).href,
					name: "高效框架搭建"
				}, {
					img: new URL('../assets/index7.png', import.meta.url).href,
					name: '线上线下互通'
				}, {
					img: new URL('../assets/index8.png', import.meta.url).href,
					name: "功能全面升级"
				}, {
					img: new URL('../assets/index9.png', import.meta.url).href,
					name: "系统无限扩展"
				}],
				list1: [{}, {}, {}, {}, {}, {}],
				list: [{
					img: new URL('../assets/index1.png', import.meta.url).href,
					name: '提升销售业绩',
					name1: "提升业绩"
				}, {
					img: new URL('../assets/index2.png', import.meta.url).href,
					name: '提高企业运作效率',
					name1: "提升效率"
				}, {
					img: new URL('../assets/index3.png', import.meta.url).href,
					name: '多元化营销',
					name1: "多元化营销"
				}, {
					img: new URL('../assets/index4.png', import.meta.url).href,
					name: '线上线下深度融合',
					name1: "多维度结合"
				}, {
					img: new URL('../assets/index5.png', import.meta.url).href,
					name: '数据互联互通',
					name1: "打通数据"
				}]
			}
		},
		mounted() {
			new Swiper('.swiper-container', {
				loop: true,
				autoplay: true, //自动轮播
				clickable: true,
				pagination: {
					el: '.swiper-pagination',
				},
			});
		},
		methods: {
			handleMouseEnter(index) {
				this.indexs = index
			}
		}
	}
</script>

<style scoped lang="less">
	.swiper-container {
		width: 100%;
		height: 350px;
		overflow: hidden;


	}

	.swiper-slide {
		width: 100%;
		height: 350px;
		text-align: center;
		font-size: 18px;
		background: #fff;
		position: relative;

		img {
			width: 100%;
			height: 350px;
		}
	}
	.lbjieshao{
		position: absolute;
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: #666;
		left: 0px;
		bottom: 0px;
		color: #fff;
		font-size: 12px;
		
	}

	.all_title {
		width: 100%;

		margin-top: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

	}

	.all_title>div:nth-of-type(1) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 26px;
		color: #000000;
		font-style: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: none;
	}

	.all_title>div:nth-of-type(2) {
		width: 51px;
		height: 2px;
		background: #63470F;
		margin-top: 10px;
	}

	.all_title>div:nth-of-type(3) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		margin-top: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #999999;
		line-height: 20px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}

	.index_banner {
		width: 100%;
		height: 333px;
		position: relative;

		img {
			width: 100%;
			height: 333px;
		}
	}

	.bgi_word {
		position: absolute;
		left: 12%;
		top: 90px;
	}

	.bgi_word>div:nth-of-type(1) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 28px;
		color: #000000;
		font-style: normal;
	}

	.bgi_word>div:nth-of-type(2) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #575552;
		font-style: normal;
		margin-top: 15px;
	}

	.bgi_word>div:nth-of-type(3) {
		width: 140px;
		height: 40px;
		background-color: #9D6C00;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 12px;
		margin-top: 50px;
	}

	.bgi_pa {
		width: 80%;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.11);
		position: absolute;
		left: 10%;
		border-radius: 10px;
		bottom: -80px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bgi_pa_con {
		width: 18%;
		display: flex;
		align-items: center;

	}

	.bgi_pa_con>div {
		margin-left: 10px;
	}

	.bgi_pa_con>img {
		width: 50px;
		height: 50px;
	}

	.bgi_pa_con>div>div:nth-of-type(1) {
		font-family: PingFangSC, PingFang SC;

		font-size: 17px;
		color: #333333;
		font-style: normal;
		text-transform: none;
	}

	.bgi_pa_con>div>div:nth-of-type(2) {
		font-family: PingFangSC, PingFang SC;

		font-size: 13px;
		color: #999999;
		font-style: normal;
		text-transform: none;
		margin-top: 5px;
	}

	.online_box {
		width: 80%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

	}

	.online_item {
		width: 32%;
		height: 220px;
		border: 1px solid #E0E0E0;
		margin-top: 40px;
	}

	.online_item_top {
		width: 100%;
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 24px;
		color: #FFFFFF;
		font-style: normal;
		text-transform: none;
		background-color: #B48A38;
	}

	.online_item_bottom {
		width: 100%;
		height: 130px;

		display: flex;
		flex-direction: column;
		padding-top: 37px;


	}

	.online_item_bottom>div:nth-of-type(1) {
		font-family: PingFangSC, PingFang SC;
		height: 20px;
		font-weight: 500;
		font-size: 16px;
		color: #000000;
		font-style: normal;
		text-transform: none;
		border-left: 3px solid #5140CF;
		margin-left: 40px;
		padding-left: 20px;
	}

	.online_item_bottom>div:nth-of-type(2) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #666666;
		font-style: normal;
		text-transform: none;
		margin-top: 15px;
		margin-left: 40px;
		padding-left: 23px;
	}

	.salenew_box {
		width: 100%;
		margin-top: 50px;
		height: 350px;
		background-color: #2F363E;
		position: relative;

	}

	.salenew_box_img {

		width: 100%;

		height: 350px;
	}

	.salenew_box_small {
		position: absolute;
		width: 72%;
		left: 14%;
		top: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.salenew_small_item {
		width: 186px;
		height: 210px;
		background: RGBA(31, 36, 43, 1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 80px;
			height: 80px;
		}

		div {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #F1F1F1;
			font-style: normal;
			text-transform: none;
			margin-top: 10px;
		}
	}

	.platform_box {
		width: 80%;
		margin: 50px auto;
		height: 320px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.platform_item {
		width: 24%;
		height: 320px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.platform_item>img {
		width: 100%;
		height: 180px;
	}

	.platform_item>div {
		width: 100%;
		height: 140px;
		background-color: #F4F4F4;
		padding-top: 17px;

	}

	.platform_item>div>div:nth-of-type(1) {
		width: 90%;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #B48A38;
		font-style: normal;
		text-transform: none;
		padding-left: 17px;
	}

	.platform_item>div>div:nth-of-type(2) {
		width: 90%;
		padding-left: 17px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 12px;
		color: #8D8D8D;
		margin-top: 12px;
		font-style: normal;
		text-transform: none;
	}

	.index_news {
		width: 100%;
		height: 540px;
		background-color: #292E35;
	}

	.index_news_s {
		width: 80%;
		margin: 20px auto;
	}

	.xwzx_box {
		width: 100%;
		line-height: 80px;
		text-align: center;
		border-bottom: 1px solid #eee;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 26px;
		color: #FFFFFF;
		font-style: normal;
		text-transform: none;
	}

	.xwzx_box_b {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 40px;
	}

	.xwzx_box_b_l {

		width: 40%;
	}

	.xwzx_box_b_r {
		width: 60%;
		margin-left: 40px;
	}

	.xwzx_box_b_item {

		display: flex;
		align-items: flex-start;
		margin-top: 28px;
		cursor: pointer;
	}

	.xwzx_box_b_item_l {
		width: 8px;
		height: 8px;
		border-radius: 50%;

		margin-top: 10px;
	}

	.xwzx_box_b_item_r {
		margin-left: 20px;
	}

	.xwzx_box_b_item_r>div:nth-of-type(1) {
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	.xwzx_box_b_item_r>div:nth-of-type(1)>div:nth-of-type(1) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		font-style: normal;
	}

	.xwzx_box_b_item_r>div:nth-of-type(1)>div:nth-of-type(2) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		font-style: normal;
	}

	.xwzx_box_b_item_r>div:nth-of-type(2) {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 22px;
		text-align: left;
		font-style: normal;
		margin-top: 12px;
	}

	.ckgd {
		width: 144px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
		box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.11);
		margin: 30px auto;
		font-size: 13px;
		color: #999999;
		cursor: pointer;
	}
</style>